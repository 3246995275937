import { Input, Button, Spin, Select, message, Switch, Checkbox } from 'antd';
import { SelectValue } from 'antd/es/select';
import update from 'immutability-helper';
import { capitalize } from 'lodash';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext, DraggingStyle, DragStart, DropResult, NotDraggingStyle } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { articleBlocksService, articlesService, categoriesService } from 'config/services';

import { HttpError } from 'helpers/http';

import './index.scss';
import { SessionState, StoreState } from 'types/store';

import { PaginationParams } from '../../../types/common';
import {
  ArticleBlock,
  ArticleBlockPayloadWithId,
  ArticleBlocks,
  UpdateMultipleBlocksPayload,
} from '../../../types/services/article-blocks';
import { Article, ArticleOptions, PaginatedArticles } from '../../../types/services/articles';
import { Category, CategoryResponse } from '../../../types/services/categories';
import { move, reorder } from '../../../utility/dndHelpers';

import { ArticleBlockDrawer2 } from './ArticlesBlockDrawer2';
import { ArticlesList } from './components/ArticlesList/ArticlesList';

export const ArticleBlocksEdit = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [blocks, setBlocks] = useState<ArticleBlock[]>([]);
  const [oldBlocks, setOldBlocks] = useState<any[]>([]);
  const [showBlockArticles, setShowBlockArticles] = useState<boolean>(true);
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

  const {
    isLoading: isBlocksLoading,
    isFetching: isBlocksFetching,
    isError: isBlocksError,
    data: blocksResponse,
    refetch: refetchArticleBlocks,
  } = useQuery<ArticleBlocks, HttpError>(
    ['listBlocksWithArticles'],
    () => articleBlocksService.list({ includeArticles: true, active: true }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (blocksResponse) {
      setOldBlocks(blocksResponse.data.blocks);
      setBlocks(blocksResponse.data.blocks);
    }
  }, [blocksResponse]);

  const {
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
    isError: isCategoriesError,
    data: categoriesResponse,
  } = useQuery<CategoryResponse, HttpError>('listCategories', () => categoriesService.list({ onlyRoots: true }), {
    refetchOnWindowFocus: false,
  });

  const { isLoading: isMultipleUpdateLoading, mutateAsync: updateMultipleBlocks } = useMutation(
    'updateMultipleBlocks',
    (payload: UpdateMultipleBlocksPayload) => articleBlocksService.updateMultipleBlocks(payload),
  );

  const { mutateAsync: updateArticleBlock } = useMutation(
    'updateArticleBlock',
    (blockArticle: ArticleBlockPayloadWithId) => articleBlocksService.patch(blockArticle),
  );

  const categories: Category[] = categoriesResponse?.data || [];

  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 100 });
  const [articleOptions, setArticleOptions] = useState<ArticleOptions>({ status: ['approved'] });
  const [searchCategoryId, setSearchCategoryId] = useState<number>(0);
  const [draggableCategoryId, setDraggableCategoryId] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editedBlock, setEditedBlock] = useState<ArticleBlockPayloadWithId>();
  const [isDisabled, setIsDisabled] = useState(false);

  const closeDrawer = () => {
    setOpenDrawer(false);
    setEditedBlock(undefined);
  };

  const {
    isLoading: isArticlesLoading,
    isFetching: isArticlesFetching,
    isRefetching: isArticlesRefetching,
    isError: isArticlesError,
    data: articlesResponse,
    refetch: refetchArticles,
  } = useQuery<PaginatedArticles, HttpError>(['listArticles'], () => articlesService.list(pagination, articleOptions), {
    refetchOnWindowFocus: false,
  });

  const getAllBlockArticlesIds = (): number[] => {
    const allBlockArticles: Article[] = blocks.map((b) => b.articles).flat();
    return allBlockArticles.map((a) => a.id);
  };

  const getAllManualsBlockArticlesIds = (): number[] => {
    const allBlockArticles: Article[] = blocks
      ? blocks
          .filter((i: ArticleBlock) => !i.automatic)
          .map((b: ArticleBlock) => b.articles)
          .flat()
      : [];
    return allBlockArticles.map((a) => a.id);
  };

  useEffect(() => {
    refetchArticles();
  }, [pagination, refetchArticles]);

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleOptions]);

  const isError = () => {
    return isBlocksError || isCategoriesError || isArticlesError;
  };

  const isLoading = () => {
    return isBlocksLoading || isBlocksFetching || isCategoriesLoading || isCategoriesFetching;
  };

  if (isError()) {
    return (
      <div>
        <pre>{JSON.stringify(t('common:Error'), undefined, 2)}</pre>
      </div>
    );
  }

  if (!blocksResponse || !categoriesResponse || !articlesResponse || isLoading() || isMultipleUpdateLoading) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  let articles: Article[] = [];
  //total: number = 0;
  if (articlesResponse) {
    articles = articlesResponse.data;
    //total = articlesResponse.pagination.total;
  }

  // const onPaginationChange = (page: number) => {
  //   setPagination((previousPagination) => ({ ...previousPagination, page }));
  // };

  // const onShowSizeChange = (current: number, pageSize: number) => {
  //   setPagination({ ...pagination, page: current, perPage: pageSize });
  // };

  // TODO error and loading

  // const onArticlesIdChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setArticleOptions({
  //     ...articleOptions,
  //     id: e.target.value !== '' ? Number(e.target.value) : undefined,
  //   });
  // };

  const onArticlesTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setArticleOptions({
      ...articleOptions,
      title: [String(e.target.value)],
    });
  };

  const onArticlesCatChange = (value: SelectValue) => {
    setArticleOptions({
      ...articleOptions,
      categoryId: value === 0 ? -1 : Number(value),
    });
    setSearchCategoryId(Number(value));
  };

  // const onArticlesSearch = () => {
  //   setPagination({ ...pagination, page: 1 });
  // };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#efe4e3' : 'rgb(240,240,240)',
  });

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    article: Article,
  ) => ({
    userSelect: 'none',
    background: isDragging ? '#dbf1e8' : '#fff',
    borderTop: isDragging && '2px solid #63b588',
    borderRight: isDragging && '2px solid #63b588',
    borderBottom: isDragging && '2px solid #63b588',
    //borderLeft: `2px solid ${article.category.color}`,
    ...draggableStyle,
  });

  const isDropDisabled = (block: ArticleBlock): boolean => {
    const getMembers: any = (members: any[]) => {
      let children: any = [];
      const flattenMembers = members.map((m: any) => {
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        return m;
      });

      return flattenMembers.concat(children.length ? getMembers(children) : children);
    };

    const result = block.category ? getMembers([block.category]).map((i: any) => i.id) : [];
    const allChildren = result;
    // if (block.id === 5) console.log(result, 'result');

    return (
      block.automatic || (block.category !== null && ![block.category.id, ...result].includes(draggableCategoryId))
    );
  };

  const onDragStart = (initial: DragStart) => {
    const { source, draggableId } = initial;
    const articleId = Number(draggableId.split('-')[1]);
    let article;
    if (source.droppableId === 'articlesPool') article = articles.find((a) => a.id === articleId);
    else {
      const blockId = Number(source.droppableId);
      article = blocks.find((b) => b.id === blockId)?.articles.find((a) => a.id === articleId);
    }
    const categoryId = article?.category.id;
    if (categoryId) setDraggableCategoryId(categoryId);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const destinationBlockId = Number(destination.droppableId);
    const destinationBlock = blocks.find((b) => b.id === destinationBlockId);
    if (!destinationBlock) return;
    const destinationBlockIndex = blocks.findIndex((b) => b.id === destinationBlockId);

    // case 1: move articles from articles pool to block
    if (source.droppableId === 'articlesPool') {
      if (destinationBlock?.type?.numberOfArticles !== undefined) {
        if (destinationBlock?.type?.numberOfArticles <= destinationBlock?.articles.length) {
          return;
        }
      }
      const result = move<Article>(articles, destinationBlock.articles, source, destination);
      setBlocks(update(blocks, { [destinationBlockIndex]: { articles: { $set: result[destinationBlock.id] } } }));
    }
    // case 2: reorder articles inside single block
    else if (source.droppableId === destination.droppableId) {
      const reorderedArticles = reorder<Article>(destinationBlock.articles, source.index, destination.index);
      setBlocks(update(blocks, { [destinationBlockIndex]: { articles: { $set: reorderedArticles } } }));
    }
    // case 3: move articles from one block to another
    else {
      if (destinationBlock?.type?.numberOfArticles !== undefined) {
        if (destinationBlock?.type?.numberOfArticles <= destinationBlock?.articles.length) {
          return;
        }
      }
      const sourceBlockId = Number(source.droppableId);
      const sourceBlock = blocks.find((b) => b.id === sourceBlockId);
      if (!sourceBlock) return;
      const sourceBlockIndex = blocks.findIndex((b) => b.id === sourceBlockId);
      const result = move<Article>(sourceBlock.articles, destinationBlock.articles, source, destination);
      setBlocks(
        update(blocks, {
          [destinationBlockIndex]: { articles: { $set: result[destinationBlock.id] } },
          [sourceBlockIndex]: { articles: { $set: result[sourceBlock.id] } },
        }),
      );
    }
  };

  const deleteArticleFromBlock = (articleId: number, blockId: number) => {
    const block = blocks.find((b) => b.id === blockId);
    if (!block) return;
    const blockIndex = blocks.findIndex((b) => b.id === blockId);
    const updatedArticles = block.articles.filter((a) => a.id !== articleId);
    setBlocks(update(blocks, { [blockIndex]: { articles: { $set: updatedArticles } } }));
  };

  const onReset = () => {
    window.location.reload();
  };

  const onSave = async () => {
    const payload = {
      blocks: blocks.map((b) => {
        return { id: b.id, articleIds: b.articles.map((a) => a.id), automatic: b.automatic };
      }),
      email: user?.email,
    };

    try {
      await updateMultipleBlocks(payload);
      refetchArticleBlocks();
      message.success(t('articleBlock:Multiple update success'));
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 5000);
    } catch (e: any) {
      message.error(e.payload.error.message);
    }
  };

  const navigateToArticle = async (id: number, checked: boolean) => {
    const newBlocksState = blocks.map((i: ArticleBlock) => {
      if (i.id === id) i.automatic = checked;
      return i;
    });

    /*     console.log(
      'novo stanje',
      newBlocksState.map((i: ArticleBlock) => i.automatic),
    ); */

    setBlocks(newBlocksState);
    message.success(
      t(`common:Successfully Updated`, {
        resource: capitalize(t('articleBlock:Article block')),
      }),
    );
  };

  const removeBlocks = function (value: string) {
    return value !== 'Bebe' && value !== 'Zdravlje' && value !== 'En';
  };

  return (
    <div className="blocks-edit-container">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="main-section">
          <div className="blocks">
            {blocks
              .filter((block) => removeBlocks(block.name))
              .map((block) => (
                <div className="single-block" key={block.id} style={{ border: `1px solid ${block.color}` }}>
                  <div className="block-header">
                    <div className="color-square" style={{ background: block.color }} />
                    <label className="block-name">
                      {block.name}
                      {' (' + block.type?.numberOfArticles + ')'}
                    </label>

                    {block.automatic && (
                      <label className="auto-note">
                        <i>{`(${t('articleBlock:automatic')})`}</i>
                      </label>
                    )}
                    {/* {block.name !== 'Glavni' && block.name !== 'Izdvojeno' && (
                      <Switch
                        onChange={(checked: boolean) => {
                          navigateToArticle(block.id, checked);
                        }}
                        checked={block.automatic}
                        style={{ marginLeft: 'auto' }}
                      />
                    )} */}
                  </div>
                  <div className="block-articles">
                    <ArticlesList
                      articles={block.articles}
                      droppableId={`${block.id}`}
                      automatic={`${block.automatic}`}
                      getListStyle={getListStyle}
                      getItemStyle={getItemStyle}
                      isDropDisabled={isDropDisabled(block)}
                      isAllDisabled={block.automatic}
                      onDelete={deleteArticleFromBlock}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="articles-pool">
            <div className="articles-search-form" style={{ marginBottom: '15px' }}>
              {/* <div className="id">
                <label>{t('article:id')}</label>
                <Input type="number" onChange={debounce(onArticlesIdChange, 300)} />
              </div> */}
              <div className="title">
                <label>{t('article:title')}</label>
                <Input type="text" onChange={debounce(onArticlesTitleChange, 300)} />
              </div>
              <div className="category">
                <label>{t('articleBlock:category')}</label>
                <Select defaultValue={0} onChange={onArticlesCatChange} value={searchCategoryId}>
                  <Option value={0}>{t('common:All categories')}</Option>
                  {categories.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.title}
                    </Option>
                  ))}
                </Select>
              </div>
              {/* <Button
                type="primary"
                htmlType="button"
                onClick={onArticlesSearch}
                loading={isArticlesLoading}
                icon={<SearchOutlined />}
              >
                {t('common:Search')}
              </Button> */}
            </div>
            <div style={{ display: 'flex', marginBottom: '15px' }}>
              <Checkbox
                defaultChecked={showBlockArticles}
                onClick={() => setShowBlockArticles(!showBlockArticles)}
                checked={showBlockArticles}
              ></Checkbox>
              <div style={{ color: '#8b8b8b', paddingLeft: '10px', fontWeight: '400' }}>
                Sakrij članke koji se nalaze u blokovima
              </div>
            </div>
            {/* <Pagination
              showSizeChanger
              onChange={onPaginationChange}
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={pagination.page}
              pageSize={100}
              total={total}
            /> */}
            {isArticlesLoading || isArticlesFetching || isArticlesRefetching ? (
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <Spin size="small" />
              </div>
            ) : (
              <ArticlesList
                blocks={blocks}
                articles={articles}
                allBlockArticlesIds={getAllBlockArticlesIds()}
                droppableId="articlesPool"
                getListStyle={getListStyle}
                getItemStyle={getItemStyle}
                isDropDisabled
                showBlockArticles={showBlockArticles}
                allManualsBlockArticlesIds={getAllManualsBlockArticlesIds()}
              />
            )}
          </div>
        </div>
      </DragDropContext>

      <div className="article-block-footer">
        <Button type="default" htmlType="button" onClick={onReset}>
          {t('common:Cancel')}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSave}
          loading={isMultipleUpdateLoading}
          disabled={isDisabled}
        >
          {t('common:Save')}
        </Button>
      </div>
      {openDrawer && (
        <ArticleBlockDrawer2
          open={openDrawer}
          refetchArticleBlocks={refetchArticleBlocks}
          blockArticle={editedBlock}
          onCancel={closeDrawer}
        />
      )}
    </div>
  );
};
