import { Row, Table, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { articleBlocksService, blockUpdateLogService } from 'config/services';

import { HttpError } from 'helpers/http';

import { ArticleBlocks } from 'types/services/article-blocks';
import { BlockUpdateLog } from 'types/services/block-update-log';

export const BlockUpdateLogDetail = () => {
  const { Column } = Table;
  const { id } = useParams<{ id?: string }>();

  const {
    isLoading: isLoadingSingle,
    isFetching: isFetchingSingle,
    isError: isErrorSingle,
    data: responseSingle,
    error: errorSingle,
  } = useQuery<BlockUpdateLog, HttpError>(['get'], () => blockUpdateLogService.get(Number(id)), {
    enabled: typeof id !== undefined,
  });

  const {
    isLoading: isBlocksLoading,
    isFetching: isBlocksFetching,
    isError: isBlocksError,
    data: blocksResponse,
  } = useQuery<ArticleBlocks, HttpError>(
    ['listBlocksWithArticles'],
    () => articleBlocksService.list({ includeArticles: true, active: true }),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isBlocksError || isErrorSingle) {
    return (
      <div>
        <pre>{JSON.stringify(errorSingle, undefined, 2)}</pre>
      </div>
    );
  }

  if (!blocksResponse || !responseSingle) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  function transformSingle(body: any) {
    // console.log('evo naskeeee', body);
    // console.log('evo blokova', blocksResponse);
    const changes: any = [];
    const fullQuery = body['query'];
    // console.log(fullQuery, 'jedan element');
    const singleElements = fullQuery.split(', ');
    // console.log(singleElements, 'single');
    let checker = '';
    singleElements.forEach((element: string) => {
      const temp = {
        block: '',
        position: '',
        article: '',
      };
      const entries = element.slice(1, -1).split(',');
      //   console.log(entries, 'entriesssssssss');
      //   console.log(checker !== entries[0], checker, entries[0]);
      if (checker !== entries[0]) {
        let blockName = blocksResponse?.data.blocks.find((block) => block.id === Number(entries[0]))?.name;
        temp.block = blockName ?? entries[0];
        temp.position = entries[1];
        temp.article = entries[1] + '-' + entries[2];
        changes.push(temp);
        checker = entries[0];
      } else {
        changes[changes.length - 1].article += ' / ' + entries[1] + '-' + entries[2];
      }
      //   console.log(changes[changes.length - 1], 'izmjeneeee');
    });

    return changes;
  }

  return (
    <>
      <div style={{ display: 'flex' }} className={'logTable'}>
        <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>
          <Row gutter={[8, 16]}>
            <Table
              bordered
              sticky
              size="middle"
              loading={isLoadingSingle || isFetchingSingle || isBlocksLoading || isBlocksFetching}
              dataSource={transformSingle(responseSingle.body)}
              pagination={{
                position: ['bottomCenter'],
                pageSize: 20,
              }}
            >
              <Column
                key="block"
                dataIndex="block"
                title={'Blok'}
                //filters={userFilterOptions}
                //filterSearch
                width={'120px'}
              />
              {/* <Column
                key="position"
                dataIndex="position"
                title={'Pozicija'}
                //filters={userFilterOptions}
                //filterSearch
                width={'120px'}
              /> */}
              <Column
                key="article"
                dataIndex="article"
                title={'Pozicija - Članak | nakon izmjene'}
                //filters={userFilterOptions}
                //filterSearch
                // width={'120px'}
              />
            </Table>
          </Row>
        </div>
      </div>
    </>
  );
};
