import { ActivityLogService } from 'services/activity-log';
import { ArticleBlockTypesService } from 'services/article-block-types';
import { ArticleBlocksService } from 'services/article-blocks';
import { ArticleSubTypeService } from 'services/article-sub-type';
import { ArticlesService } from 'services/articles';
import { AuthService } from 'services/auth';
import { BlockUpdateLogService } from 'services/block-update-log';
import { CategoriesService } from 'services/categories';
import { CommentsService } from 'services/comments';
import { CoverImagesService } from 'services/coverImages';
import { CrosswordsService } from 'services/crosswords';
import { CustomersService } from 'services/customers';
import { HomeStatisticsService } from 'services/home-statistics';
import { ImagesService } from 'services/images';
import { NotificationsService } from 'services/notifications';
import { RbacService } from 'services/rbac/service';
import { RSSService } from 'services/rss';
import { StatisticsService } from 'services/statistics';
import { TagService } from 'services/tags';
import { UsersService } from 'services/users';

import { client } from './client';

export const activityLogService = new ActivityLogService(client);
export const blockUpdateLogService = new BlockUpdateLogService(client);
export const authService = new AuthService(client);
export const customersService = new CustomersService(client);
export const imagesService = new ImagesService(client);
export const coverImagesService = new CoverImagesService(client);
export const usersService = new UsersService(client);
export const rbacService = new RbacService(client);
export const tagsService = new TagService(client);
export const notificationsService = new NotificationsService(client);
export const categoriesService = new CategoriesService(client);
export const articlesService = new ArticlesService(client);
export const crosswordsService = new CrosswordsService(client);
export const commentsService = new CommentsService(client);
export const articleBlocksService = new ArticleBlocksService(client);
export const articleBlockTypesService = new ArticleBlockTypesService(client);
export const statisticsService = new StatisticsService(client);
export const homeStatisticsService = new HomeStatisticsService(client);
export const rssService = new RSSService(client);
export const articleSubTypeService = new ArticleSubTypeService(client);
