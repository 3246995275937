import { ActivityLog } from 'types/services/activity-log';
import { BlockUpdateLog } from 'types/services/block-update-log';
import { CategoryData, DateData, Statistic, StatisticSubType } from 'types/services/statistics';

export const transformResource = (resource: Statistic) => {
  var preuzetiT = 0;
  var tekstDrNov = 0;
  var agencijskiT = 0;
  var autorskiT = 0;
  var prevedeniT = 0;
  var promoT = 0;

  for (var i = 0; i < resource.data.length; i++) {
    if (resource.data[i].textType === 'Preuzeti tekst') {
      preuzetiT = resource.data[i].count;
    }
    if (resource.data[i].textType === 'Tekst drugog novinara') {
      tekstDrNov = resource.data[i].count;
    }
    if (resource.data[i].textType === 'Agencijski tekst') {
      agencijskiT = resource.data[i].count;
    }
    if (resource.data[i].textType === 'Autorski tekst') {
      autorskiT = resource.data[i].count;
    }
    if (resource.data[i].textType === 'Prevedeni tekst') {
      prevedeniT = resource.data[i].count;
    }
    if (resource.data[i].textType === 'Promo tekst') {
      promoT = resource.data[i].count;
    }
  }

  return {
    key: resource.authorName,
    authorName: resource.authorName,
    preuzetiTekst: preuzetiT,
    tekstDrugogNovinara: tekstDrNov,
    agencijskiTekst: agencijskiT,
    autorskiTekst: autorskiT,
    prevedeniTekst: prevedeniT,
    promoTekst: promoT,
  };
};

export const transformResourceStatistic = (resource: StatisticSubType) => {
  return {
    key: resource.id,
    subType: resource.subType,
    count: resource.count,
  };
};

export const transformLogs = (resource: ActivityLog) => {
  return {
    id: resource.id,
    article: resource.article ?? '',
    title: resource.article.title,
    event: resource.event,
    user: resource.user ? resource.user?.firstName + ' ' + resource.user?.lastName : '',
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ),
  };
};

export const transformBlockUpdateLogs = (resource: BlockUpdateLog) => {
  return {
    id: resource.id,
    user: resource.user ? resource.user?.firstName + ' ' + resource.user?.lastName : '',
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ),
  };
};

export const transformCategory = (resource: CategoryData) => {
  return {
    categoryName: resource.title,
    numberOfHits: resource.numberOfHits,
  };
};

export const transformByDay = (resource: DateData) => {
  return {
    date: resource.tt,
    numberOfHits: resource.count,
  };
};
