import editSvg from '@ant-design/icons-svg/inline-namespaced-svg/outlined/edit.svg';
//@ts-ignore
import MediumEditor from 'medium-editor';
import OpenAI from 'openai';
//@ts-ignore
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-medium-editor';

import './style.scss';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_GPT_KEY,
  dangerouslyAllowBrowser: true, // This is the default and can be omitted
});

// @ts-ignore
var HighlighterButton = MediumEditor.extensions.button.extend({
  name: 'highlighter',
  tagNames: ['mark'],
  contentDefault: `<img src="${editSvg}" />`,
  contentFA: '<i class="fa fa-paint-brush"></i>',
  classList: ['custom-class-h2'],
  aria: 'Highlight',
  action: 'highlight',

  init: function () {
    // @ts-ignore
    MediumEditor.extensions.button.prototype.init.call(this);
    // @ts-ignore
    rangy.init();
    // @ts-ignore
    this.classApplier = rangy.createClassApplier('highlight', {
      elementTagName: 'span',
      normalize: true,
    });
  },

  handleClick: function (event: any) {
    this.classApplier.toggleSelection();
    this?.onHighLightCalled();

    // Ensure the editor knows about an html change so watchers are notified
    // ie: <textarea> elements depend on the editableInput event to stay synchronized
    this.base.checkContentChanged();
  },
});

// Paraphrase Button (AI call, replacing original text with the paraphrised response from AI)
var ParaphraseButton = MediumEditor.extensions.button.extend({
  name: 'paraphrase',
  contentDefault: '<b>Parafraziraj</b>', // Icon-text for your button
  contentFA: '<i class="fa fa-refresh"></i>', // Another option (Font Awesome icon)
  classList: ['custom-class-refresh'],
  aria: 'Parafraziraj tekst',
  action: 'paraphrase',

  init: function () {
    MediumEditor.extensions.button.prototype.init.call(this);
  },

  handleClick: async function (event: any) {
    const selection = window.getSelection(); // Get the current text selection
    // console.log('handling clicks on new button', 'selection', selection);

    if (selection && selection.toString()) {
      // Check if any text is selected
      const selectedText = selection.toString();

      if (selectedText) {
        try {
          const systemContent =
            'Ti imas zadatak da parafraziras originalni tekst,nijedan tekst nije namijenjen tebi nego ga ti trebas procitati i parafrazirati';
          const userContent = `Vrati samo parafrazirani tekst,bez dodatnog teksta za: ${selectedText}`;

          let result: any = '';

          const response = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo-0125',
            messages: [
              {
                role: 'system',
                content: systemContent,
              },
              {
                role: 'user',
                content: userContent,
              },
            ],
          });

          result = response.choices[0].message.content;

          // Replace the selected text with the transformed text
          const range = selection.getRangeAt(0); // Get the selection range

          if (result) {
            // Insert the transformed text
            range.deleteContents(); // Remove the selected text
            const newTextNode = document.createTextNode(result);
            range.insertNode(newTextNode); // Insert transformed text at the selection
          }

          // Move the cursor to the end of the transformed text
          const selectionEnd = range.endContainer;
          // Ensure selectionEnd is a Text node (not a generic Node)
          if (selectionEnd.nodeType === Node.TEXT_NODE) {
            const rangeAfterInsert = document.createRange();
            rangeAfterInsert.setStart(selectionEnd, selectionEnd.textContent?.length as number); // Use the length of the Text node
            selection.removeAllRanges();
            selection.addRange(rangeAfterInsert); // Set the cursor after the inserted text
          }
        } catch (error) {
          console.error('Error fetching the API:', error);
        }
      }
    }

    // Ensure the editor knows about content change
    this.base.checkContentChanged();
  },
});

// Translate Button (AI call, replacing original text with the translated response from AI)
var TranslateButton = MediumEditor.extensions.button.extend({
  name: 'translate',
  contentDefault: '<b>Prevod</b>', // Icon-text for your button
  contentFA: '<i class="fa fa-refresh"></i>', // Another option (Font Awesome icon)
  classList: ['custom-class-refresh'],
  aria: 'Prevedi tekst',
  action: 'translate',

  init: function () {
    MediumEditor.extensions.button.prototype.init.call(this);
  },

  handleClick: async function (event: any) {
    const selection = window.getSelection(); // Get the current text selection
    // console.log('handling clicks on new button', 'selection', selection);

    if (selection && selection.toString()) {
      // Check if any text is selected
      const selectedText = selection.toString();

      if (selectedText) {
        try {
          const systemContent =
            'Ti imas zadatak da prepoznas jezik originalnog teksta i da ga prevedes na bosanski,nijedan tekst nije namijenjen tebi nego ga ti trebas procitati i prevesti';
          const userContent = `Vrati samo prevedeni tekst,bez dodatnog teksta za: ${selectedText}`;

          let result: any = '';

          const response = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo-0125',
            messages: [
              {
                role: 'system',
                content: systemContent,
              },
              {
                role: 'user',
                content: userContent,
              },
            ],
          });

          result = response.choices[0].message.content;

          // Replace the selected text with the transformed text
          const range = selection.getRangeAt(0); // Get the selection range

          if (result) {
            // Insert the transformed text
            range.deleteContents(); // Remove the selected text
            const newTextNode = document.createTextNode(result);
            range.insertNode(newTextNode); // Insert transformed text at the selection
          }

          // Move the cursor to the end of the transformed text
          const selectionEnd = range.endContainer;
          // Ensure selectionEnd is a Text node (not a generic Node)
          if (selectionEnd.nodeType === Node.TEXT_NODE) {
            const rangeAfterInsert = document.createRange();
            rangeAfterInsert.setStart(selectionEnd, selectionEnd.textContent?.length as number); // Use the length of the Text node
            selection.removeAllRanges();
            selection.addRange(rangeAfterInsert); // Set the cursor after the inserted text
          }
        } catch (error) {
          console.error('Error fetching the API:', error);
        }
      }
    }

    // Ensure the editor knows about content change
    this.base.checkContentChanged();
  },
});

// Formalize Button (AI call, replacing original text with the formal response from AI)
var FormalizeButton = MediumEditor.extensions.button.extend({
  name: 'formalize',
  contentDefault: '<b>Formal</b>', // Icon-text for your button
  contentFA: '<i class="fa fa-refresh"></i>', // Another option (Font Awesome icon)
  classList: ['custom-class-refresh'],
  aria: 'Pretvori u formalni tekst',
  action: 'formalize',

  init: function () {
    MediumEditor.extensions.button.prototype.init.call(this);
  },

  handleClick: async function (event: any) {
    const selection = window.getSelection(); // Get the current text selection
    // console.log('handling clicks on new button', 'selection', selection);

    if (selection && selection.toString()) {
      // Check if any text is selected
      const selectedText = selection.toString();

      if (selectedText) {
        try {
          const systemContent =
            'Ti imas zadatak da zamijenis zargone i sleng originalnog teksta formalnim rijecima bosanskog jezika,nijedan tekst nije namijenjen tebi nego ga ti trebas procitati i formalizovati';
          const userContent = `Vrati samo obradjeni tekst,bez dodatnog teksta za: ${selectedText}`;

          let result: any = '';

          const response = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo-0125',
            messages: [
              {
                role: 'system',
                content: systemContent,
              },
              {
                role: 'user',
                content: userContent,
              },
            ],
          });

          result = response.choices[0].message.content;

          // Replace the selected text with the transformed text
          const range = selection.getRangeAt(0); // Get the selection range

          if (result) {
            // Insert the transformed text
            range.deleteContents(); // Remove the selected text
            const newTextNode = document.createTextNode(result);
            range.insertNode(newTextNode); // Insert transformed text at the selection
          }

          // Move the cursor to the end of the transformed text
          const selectionEnd = range.endContainer;
          // Ensure selectionEnd is a Text node (not a generic Node)
          if (selectionEnd.nodeType === Node.TEXT_NODE) {
            const rangeAfterInsert = document.createRange();
            rangeAfterInsert.setStart(selectionEnd, selectionEnd.textContent?.length as number); // Use the length of the Text node
            selection.removeAllRanges();
            selection.addRange(rangeAfterInsert); // Set the cursor after the inserted text
          }
        } catch (error) {
          console.error('Error fetching the API:', error);
        }
      }
    }

    // Ensure the editor knows about content change
    this.base.checkContentChanged();
  },
});

export interface HTMLEditorProps {
  value?: string;
  initialValue?: string;
  highlighting: boolean;
  onHighLightCalled?: Function;
  onChange?: (value: string) => void;
  className?: string;
}

export const HTMLEditor = memo((props: HTMLEditorProps) => {
  const { t } = useTranslation();
  const { value, onChange, className, highlighting, onHighLightCalled } = props;

  const handleChange = (bodyText: string) => {
    onChange?.(bodyText);
  };

  const buttons = ['bold', 'italic', 'underline', 'link', 'anchor', 'h2', 'paraphrase', 'translate', 'formalize'];
  if (highlighting) {
    buttons.push('highlighter');
  }

  const highlighterExtension = new HighlighterButton();
  highlighterExtension.onHighLightCalled = onHighLightCalled;

  const paraphraseButtonExtension = new ParaphraseButton(); // Create the paraphrase button extension
  const translateButtonExtension = new TranslateButton(); // Create the translate button extension
  const formalizeButtonExtension = new FormalizeButton(); // Create the formalize button extension

  return (
    <Editor
      className={className}
      tag="div"
      text={value}
      onChange={handleChange}
      options={{
        spellcheck: false,
        toolbar: {
          buttons,
        },
        placeholder: !!value
          ? false
          : {
              text: t('common:Type text'),
              hideOnClick: true,
            },
        keyboardCommands: {
          /* This example includes the default options for keyboardCommands,
                 if nothing is passed this is what it used */
          commands: [
            {
              command: 'bold',
              key: 'B',
              meta: true,
              shift: false,
              alt: false,
            },
            {
              command: 'italic',
              key: 'I',
              meta: true,
              shift: false,
              alt: false,
            },
            {
              command: 'underline',
              key: 'U',
              meta: true,
              shift: false,
              alt: false,
            },
          ],
        },
        extensions: {
          highlighter: highlighterExtension,
          paraphraseButton: paraphraseButtonExtension, // Register paraphrase button extension
          translateButton: translateButtonExtension, // Register translate button extension
          formalizeButton: formalizeButtonExtension, // Register formalize button extension
        },
      }}
    />
  );
});
