/* eslint-disable jsx-a11y/label-has-associated-control */

import { ArrowRightOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Checkbox, Image as Picture } from 'antd';
import { Form, Input, Button, Cascader, Spin, DatePicker, Select, message, Modal } from 'antd';
import { Collapse } from 'antd';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { TagInput } from 'components/TagInput/TagInput';
import { UseAIModal } from 'components/UseAIModal';

import { articlesService, articleSubTypeService, categoriesService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import {
  Article,
  ImagesBlock as ImagesBlockType,
  ArticlePayload,
  ArticlePayloadWithId,
  RelatedArticle,
  ArticleStatusPayload,
} from 'types/services/articles';
import { BlockType } from 'types/services/articles';
import { PermissionsMap } from 'types/services/auth';
import { CategoryResponse, Category } from 'types/services/categories';
import { Image } from 'types/services/images';
import { Tag } from 'types/services/tags';
import { PaginatedUsers, User } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import ArticleRejectionModal from './components/ArticleRejectionModal';
import BlocksEditor, { ExtendedBlockType } from './components/BlocksEditor/BlocksEditor';
import RelatedArticleCardRA from './components/BlocksEditor/RelatedArticleCardRA';
import StatusAuditModal from './components/StatusAuditModal';
// eslint-disable-next-line import/order
import { UploadImages } from './components/UploadImages/UploadImages';
import { routes } from './routes';
import { textTypes, textTypesWithNative } from './utils';

import './index.scss';

export const ArticlePage = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { id } = useParams<{ id?: string }>();

  const [articleImages, setArticleImages] = useState<Image[]>([]);
  const [relatedArticles, setRelatedArticles] = useState<RelatedArticle[]>([]);
  const [showStatusAudit, setShowStatusAudit] = useState(false);
  const [showArticleRejectionModal, setShowArticleRejectionModal] = useState(false);
  const highlightUsed = useRef(false);
  const [isActive, setActive] = useState(false);
  const [coverImageInput, setCoverImageInput] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState<Image>();
  const [sponsoredByImagePreview, setSponsoredByImagePreview] = useState<Image>();
  const [coverImageWarning, setCoverImageWarning] = useState(false);
  const [relatedArticlesByAuthorWarning, setRelatedArticlesByAuthorWarning] = useState(false);
  const [tagChecked, setTagChecked] = useState(false);
  const [showUrl, setShowUrl] = useState(false);
  const [forcePublish, setForcePublish] = useState(false);
  const [comments, setComments] = useState('1');
  const [commentsDisabled, setCommentsDisabled] = useState(false);
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  const [showAIModal, setShowAIModal] = useState(false);

  const { isLoading: categoriesLoading, data: categoriesData } = useQuery<CategoryResponse, HttpError>(
    'listCategories',
    () => categoriesService.list(),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: usersLoading, data: usersData } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  const [category, setCategory] = useState(0);

  const { data: subTypes, refetch: refetchTextType } = useQuery<any, HttpError>('getSubTypes', () =>
    articleSubTypeService.list({ textType: textType }),
  );

  const {
    isLoading,
    data: article,
    refetch,
  } = useQuery<Article, HttpError>(['getArticle', id], () => articlesService.get(parseInt(id || '')), {
    enabled: typeof id !== 'undefined' && !Number.isNaN(parseInt(id)),
  });

  const [articleType, setArticleType] = useState(location.search ? location.search.substring(6) : 'standard');

  useEffect(() => {
    if (article?.articleType && article.articleType !== undefined) setArticleType(article?.articleType);
  }, [article]);

  const [blocks, setBlocks] = useState<BlockType[]>(
    articleType === 'video'
      ? [
          {
            type: 'embedded',
            content: {
              body: '',
            },
          },
        ]
      : articleType === 'special'
      ? [
          {
            type: 'images',
            content: {
              imageIds: [],
            },
          },
          {
            type: 'images',
            content: {
              imageIds: [],
            },
          },
        ]
      : [
          {
            type: 'images',
            content: {
              imageIds: [],
            },
          },
        ],
  );

  const [textType, setTextType] = useState(
    articleType === 'mega' ? 'Autorski tekst' : articleType === 'video' ? 'Video' : 'legacy_text',
  );

  const [hasAdsChecked, setHasAdsChecked] = useState(
    article ? (textType !== 'Promo tekst' ? !article.hasAds : true) : textType === 'Promo tekst' ? true : false,
  );

  const [hasAdsDisabled, setHasAdsDisabled] = useState(false);

  const { isLoading: isLoadingCreate, mutateAsync: createArticle } = useMutation(
    'createArticle',
    ({ articlePayload, forcePublish }: { articlePayload: ArticlePayload; forcePublish: boolean }) =>
      articlesService.create(articlePayload, forcePublish),
  );

  const { isLoading: isLoadingUpdate, mutateAsync: updateArticle } = useMutation(
    'updateArticle',
    (articlePayload: ArticlePayloadWithId) => articlesService.patch(articlePayload),
  );

  const { isLoading: isStatusUpdating, mutateAsync: updateArticleStatus } = useMutation(
    'setArticleStatus',
    ({ id, status }: { id: number; status: ArticleStatusPayload }) => articlesService.setStatus(id, status),
  );

  const toggleValue = (e: any) => {
    JSON.parse(comments) ? setComments('0') : setComments('1');
  };

  const categories: any = useMemo(() => categoriesData?.data || [], [categoriesData]);
  //If categories change, change fiveCategories if necessary
  const categoriesCopy = JSON.parse(JSON.stringify(categories)) as typeof categories;
  const filteredCategories = categories.filter((c: any) => c.title !== 'Video');
  let fiveCategories: number[] = [];

  for (var i = 0; i < 5; i++) {
    fiveCategories.push(categoriesCopy[i]);
  }

  let prilog = categoriesCopy.filter((c: any) => c.slug === 'prilog');

  fiveCategories.push(prilog[0]);

  if (fiveCategories.length !== 0) {
    fiveCategories.forEach((element: any) => {
      if (element !== undefined) {
        element.children = [];
      }
    });
  }

  // postaviti pravi naziv kategorije
  const avazTv = categoriesCopy.filter((c: any) => c.title === 'Avaz TV' || c.title === 'Video');
  const alfaTv = categoriesCopy.filter((c: any) => c.title === 'Prijateljski portali');

  if (alfaTv[0] && alfaTv[0].children)
    alfaTv[0].children = alfaTv[0].children.filter((c: any) => c.title === 'Alfa TV');

  useEffect(() => {
    if (user?.role === 'COMMERCIALIST' && categoriesData) {
      if (article?.textType) {
        setTextType(article?.textType);
        form.setFieldsValue({
          textTypeId: article.textType,
        });
      } else {
        setTextType('Promo tekst');

        form.setFieldsValue({
          textTypeId: 'Promo tekst',
        });
      }
      usersData?.data.forEach((u) => {
        if (u.firstName + ' ' + u.lastName === user?.name) {
          form.setFieldsValue({
            authorId: u.id,
          });
        }
      });
    }
  }, [user, categoriesData, usersData]);

  useEffect(() => {
    if (article?.url && article.legacy) {
      setShowUrl(true);
    }
  }, [article]);

  useEffect(() => {
    if (!isLoading && article && categories) {
      const { category } = article;
      let parentId, grandParentId;
      categories?.find(
        (c: Category) =>
          c.id === category?.id ||
          c.children.find((ch: Category) => {
            const eq = ch.id === category?.id;
            if (eq) {
              parentId = c.id;
            }
            ch.children.find((gch: Category) => {
              const geq = gch.id === category.id;
              if (geq) {
                grandParentId = c.id;
                parentId = ch.id;
              }
              return geq;
            });
            return eq;
          }),
      );

      article.commentsDisabled ? setComments('0') : setComments('1');

      form.setFieldsValue({
        ...article,
        categoryId: category
          ? grandParentId
            ? [grandParentId, parentId, category.id]
            : parentId
            ? [parentId, category.id]
            : [category.id]
          : null,

        publicationTime: article.publicationTime ? moment(article.publicationTime) : null,
        authorId: article.author ? article.author.id : null,
        textTypeId: article.textType ? article.textType : null,
        subTextTypeId:
          article.textType !== 'Prevedeni tekst' &&
          article.textType !== 'Preuzeti tekst' &&
          article.subTextType !== null
            ? Object.values(article.subTextType)[2]
            : null,
        subTextTypeCustomId: article.subTextType !== null ? Object.values(article.subTextType)[2] : null,
        hasAds: article
          ? textType !== 'Promo tekst'
            ? !article.hasAds
            : true
          : textType === 'Promo tekst'
          ? true
          : false,
      });
      setHasAdsChecked(
        article ? (textType !== 'Promo tekst' ? !article.hasAds : true) : textType === 'Promo tekst' ? true : false,
      );
      if (article.textType === 'Preuzeti tekst' && article.subTextType !== null) {
        form.setFieldsValue({
          subTextTypeId: article.subTextType ? Object.values(article.subTextType)[3] : null,
        });
      }
      if (article.textType === 'Agencijski tekst' && article.subTextType !== null) {
        form.setFieldsValue({
          subTextTypeAg: article.subTextType ? Object.values(article.subTextType)[2] : null,
        });
      }

      setTextType(article.textType ? article.textType : '');
      setBlocks(article.body);
      setArticleImages(article.articleImages.map((ai) => ({ ...ai.image, caption: ai.description })));
      setRelatedArticles(article.linkedArticles);
      highlightUsed.current = false;
    }
  }, [article, form, isLoading, categories]);

  //TODO: mjesto za dodavanje uslova za posebne vrste clanaka
  useEffect(() => {
    if (articleType === 'mega') {
      setTextType('Autorski tekst');
      form.setFieldsValue({ textTypeId: 'Autorski tekst' });
    }
    if (articleType === 'video') {
      setTextType('Video');
      form.setFieldsValue({ textTypeId: 'Video' });
    }
    if (articleType === 'special') {
      setTextType('Promo tekst');
      form.setFieldsValue({ textTypeId: 'Promo tekst' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleType]);

  useEffect(() => {
    if (article?.url && article.legacy) {
      setShowUrl(true);
    }
  }, [article]);

  useEffect(() => {
    refetchTextType();

    if (textType === 'Autorski tekst' || textType === 'Tekst drugog novinara') {
      document.querySelector('.authorButton')?.classList.remove('hide');
    } else {
      document.querySelector('.authorButton')?.classList.add('hide');
    }
    if (textType !== 'Tekst drugog novinara' && textType !== 'legacy_text') {
      if (!(textType === 'Autorski tekst' && article?.author !== undefined && id))
        usersData?.data.forEach((u) => {
          if (u.firstName + ' ' + u.lastName === user?.name) {
            form.setFieldsValue({
              authorId: u.id,
            });
          }
        });
    } else if (textType === 'Tekst drugog novinara') {
      form.setFieldsValue({
        authorId:
          (article?.author && article?.author.firstName + ' ' + article?.author.lastName !== user?.name) ||
          (article?.author && id !== 'new')
            ? article?.author.id
            : null,
      });
    }
    if (textType === 'Autorski tekst' && article?.author !== undefined && id) {
      usersData?.data.forEach((u) => {
        if (u.firstName + ' ' + u.lastName === user?.name) {
          form.setFieldsValue({
            authorId: u.id,
          });
        }
      });
    }
    form.setFieldsValue({ subTextType: '' });
    if (textType !== 'Autorski tekst' && textType !== 'Tekst drugog novinara') {
      setTagChecked(false);
    }
    if (textType === 'Native tekst') {
      usersData?.data.forEach((u) => {
        if (u.firstName + ' ' + u.lastName === user?.name) {
          form.setFieldsValue({
            authorId: u.id,
          });
        }
      });
    }
    if (textType === 'Promo tekst') {
      let tempFindCat = fiveCategories?.filter((i: any) => i?.id === article?.category.id);
      setRelatedArticles([]);
      form.setFieldsValue({
        tags: [],
        categoryId:
          tempFindCat && tempFindCat.length
            ? [article?.category.id]
            : article?.category.parent && article?.category.parent.id !== 13
            ? [article?.category.parent.id]
            : null,
      });

      setHasAdsChecked(true);
      setComments('0');
      setCommentsDisabled(true);
      setHasAdsDisabled(true);
    } else {
      setHasAdsDisabled(false);
      setCommentsDisabled(false);
      if (article === undefined) {
        setHasAdsChecked(false);
        setComments('1');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textType]);

  useEffect(() => {
    if (form.getFieldValue('thumbnailImage') !== undefined) {
      if (Array.isArray(form.getFieldValue('thumbnailImage'))) {
        setCoverImagePreview(form.getFieldValue('thumbnailImage')[0]);
      } else setCoverImagePreview(form.getFieldValue('thumbnailImage'));
      setCoverImageWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('thumbnailImage')]);

  useEffect(() => {
    if (form.getFieldValue('sponsoredBy') !== undefined) {
      if (Array.isArray(form.getFieldValue('sponsoredBy'))) {
        setSponsoredByImagePreview(form.getFieldValue('sponsoredBy')[0]);
      } else setSponsoredByImagePreview(form.getFieldValue('sponsoredBy'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('sponsoredBy')]);

  useEffect(() => {
    if (
      form.getFieldValue('categoryId') !== undefined &&
      form.getFieldValue('categoryId').length === 2 &&
      form.getFieldValue('categoryId')[0] === 1 &&
      form.getFieldValue('categoryId')[1] === 12
    ) {
      setHasAdsChecked(true);
      setHasAdsDisabled(true);
    } else {
      setHasAdsDisabled(false);
    }
    if (
      form.getFieldValue('categoryId') !== undefined &&
      form.getFieldValue('categoryId').length === 1 &&
      textType !== 'Promo tekst' &&
      textType !== 'legacy_text'
    ) {
      window.alert('Ne možete odabrati krovnu kategoriju za članak!');
      form.setFieldsValue({ categoryId: undefined });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const updateLinkedArticles = (tagIds: number[]) => {
    articlesService.related(tagIds).then((response) => {
      setRelatedArticles(response?.data.filter((a) => a.id !== article?.id) || []);
    });
  };

  const updateLinkedArticlesByAuthor = (authorId: number) => {
    let tempArticleId = article?.id || 0;
    articlesService.relatedByAuthor(authorId, tempArticleId).then((response) => {
      if (response.data.length > 1 || (response.data.length === 1 && response.data[0].id !== article?.id)) {
        setRelatedArticles(response?.data.filter((a) => a.id !== article?.id));
      } else {
        setTagChecked(false);
      }
    });
  };

  const saveArticle = (values: any) => {
    const usedImageIds = uniq(
      blocks
        .filter((block) => block.type === 'images')
        .map((block) => (block as ImagesBlockType).content.imageIds)
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []),
    );

    let userTemp;
    usersData?.data.forEach((u) => {
      if (u.firstName + ' ' + u.lastName === user?.name && u.email === user.email) {
        userTemp = u;
      }
    });

    if (values.hasAds === undefined) {
      values.hasAds = false;
    }

    const isUpdate = id && article;

    const articlePayload = {
      title: values.title,
      subtitle: values.subtitle,
      tagIds: (values.tags ?? []).map((tag: Tag) => tag.id),
      images: articleImages
        .filter((articleImage) => usedImageIds.includes(articleImage.id))
        .map((articleImage) => ({ id: articleImage.id, description: articleImage.caption })),
      categoryId: values.categoryId[values.categoryId.length - 1],
      thumbnailImageId: values.thumbnailImage
        ? (Array.isArray(values.thumbnailImage) ? values.thumbnailImage[0] : values.thumbnailImage).id
        : coverImagePreview?.id,
      textType: articleType === 'video' ? 'Video' : values.textTypeId || null,
      body: blocks,
      publicationTime: values.publicationTime
        ? values.publicationTime.format()
        : DateTime.now().set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
      authorId: user?.role === 'COMMERCIALIST' ? article?.author.id || null : values.authorId || null,
      stamp: values.stamp,
      url: values.url,
      linkedArticlesIds: relatedArticles.map((a) => a.id),
      subTextType:
        values.textTypeId === 'Preuzeti tekst' ||
        values.textTypeId === 'Prevedeni tekst' ||
        values.textTypeId === 'Native tekst'
          ? values.subTextTypeCustomId || ''
          : values.subTextTypeId || null,
      preuzetiDescription: values.textTypeId === 'Preuzeti tekst' ? values.subTextTypeId : '',
      createdBy: isUpdate ? article.author : userTemp ? userTemp : null,
      updatedBy: isUpdate ? (userTemp ? userTemp : null) : null,
      hasAds: !hasAdsChecked,
      commentsDisabled: !JSON.parse(comments),
      articleType: articleType,
      sponsoredBy: values.sponsoredBy
        ? (Array.isArray(values.sponsoredBy) ? values.sponsoredBy[0] : values.sponsoredBy).id
        : sponsoredByImagePreview?.id,
    };

    if (values.textTypeId === 'Agencijski tekst' && values.textTypeId !== null) {
      articlePayload.subTextType = values.subTextTypeAg;
    }

    return isUpdate
      ? updateArticle({ id: article?.id, ...articlePayload })
      : createArticle({ articlePayload, forcePublish });
  };

  const onFinish = (values: any) => {
    const promise = saveArticle(values);
    const isUpdate = id && article;
    promise
      .then((article) => {
        message.success(
          t(`common:Successfully ${isUpdate ? 'Updated' : 'Created'}`, { resource: t('article:Article') }),
        );
        if (!isUpdate) {
          history.push(`/article/${article.id}`);
        }
      })
      .catch((e) => {
        message.error(
          t('common:Action failed', {
            action: t(`common:${isUpdate ? 'Update' : 'Creation'}`),
            resource: t('article:Article').toLowerCase(),
            suffix: 'a',
          }),
        );
        console.error(e);
      });
  };

  const onReset = () => {
    const articleRoute = routes.find((r) => r.key === 'list-articles')?.path as string | undefined;
    articleRoute && history.push(articleRoute);
  };

  const handleToggle = (e: any) => {
    e.preventDefault();
    setActive(true);
  };

  const refuseHandleToggle = (e: any) => {
    e.preventDefault();
    setActive(false);
  };

  const handleTumbnailSelect = (image: Image) => {
    form.setFieldsValue({ ...form.getFieldsValue(true), thumbnailImage: image });
    setCoverImagePreview(image);
    setCoverImageWarning(false);
  };

  const handleBlocksChange = (editorBlocks: BlockType[]) => {
    setBlocks(editorBlocks);
  };

  const handleImagesChange = (images: Image[]) => {
    const newImages = [...articleImages];
    images.forEach((image) => {
      const aI = articleImages.find((ai) => ai.id === image.id);
      if (!aI) {
        newImages.push(image);
      } else {
        aI.source = image.source;
        aI.description = image.description;
      }
    });
    setArticleImages(newImages);
  };

  const handleFormChange = (changedValues: any) => {
    if (changedValues.tags) {
      updateLinkedArticles(changedValues.tags.map((t: Tag) => t.id));
    }
  };

  const handleAuthorChecked = (e: any) => {
    if (form.getFieldValue('authorId') === null) {
      setRelatedArticlesByAuthorWarning(true);
      setTagChecked(false);
    }
    if (e.checked && form.getFieldValue('authorId') !== undefined && form.getFieldValue('authorId') !== null) {
      setRelatedArticlesByAuthorWarning(false);
      setTagChecked(!tagChecked);
      updateLinkedArticlesByAuthor(form.getFieldValue('authorId'));
    }
    if (!e.checked) {
      setRelatedArticlesByAuthorWarning(false);
      setTagChecked(!tagChecked);
      let tagsToPass: any[] = [];
      form.getFieldValue('tags').forEach((t: any) => tagsToPass.push(t.id));
      updateLinkedArticles(tagsToPass);
    }
  };

  const toggleStatusAuditModal = () => {
    setShowStatusAudit((show) => !show);
  };

  const toggleUseAIModal = () => {
    setShowAIModal((value) => !value);
  };

  const handleArticleApprove = async () => {
    if (article) {
      await updateArticleStatus({ id: article.id, status: { status: 'approved' } });
      refetch();
      message.success(` ${t('article:Status change success')}`);
    }
  };

  const handlePreviewClick = async () => {
    window.open(
      //TODO: edit/create env file for production ie:(https://shazam.rotocms.com/clanak/726766/preview)
      process.env.SHAZAM_APP_API_URL + '/clanak/' + article?.id + '/preview',
      '_blank',
      'noopener,noreferrer',
    );
  };

  const handleArticleReject = () => {
    setShowArticleRejectionModal(true);
  };

  const handleArticleRejection = async (comment: string) => {
    if (article) {
      const payload: ArticleStatusPayload = { status: 'rejected', comment };
      if (highlightUsed.current) {
        payload.body = blocks;
      }
      const response = await updateArticleStatus({ id: article.id, status: payload });

      if (response.message === 'Article in block') {
        message.error(`Članak se nalazi u bloku!`);
      } else {
        message.success(` ${t('article:Status change success')}`);
      }
      setShowArticleRejectionModal(false);
      refetch();
    }
  };

  const closeArticleRejectionModal = () => {
    setShowArticleRejectionModal(false);
  };

  const handleHighlightCalled = () => {
    highlightUsed.current = true;
  };

  const dataLoading = categoriesLoading || isLoading || isLoadingCreate || isLoadingUpdate || isStatusUpdating;
  const everythingReady = !dataLoading;

  const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
  const permissions = permissionMap[location.pathname.match(/\/article\//g) ? '/article/:id' : location.pathname];

  const toggleCoverImageInput = () => {
    setCoverImageInput(!coverImageInput);
  };

  const showCoverImageWarning = () => {
    if (coverImagePreview === undefined) setCoverImageWarning(true);
  };

  const textTypeChange = (event: any) => {
    setTextType(event);
    form.setFieldsValue({
      subTextTypeId: '',
      subTextTypeAg: '',
      subTextTypeCustomId: '',
    });

    if (!id) {
      form.setFieldsValue({ authorId: '' });
    }
    setRelatedArticles([]);
  };

  const categoryChange = (event: any) => {
    event !== undefined ? setCategory(event) : setCategory(0);
  };

  const userArr: User[] = [];
  usersData?.data.forEach((u) => {
    if (u.firstName + ' ' + u.lastName !== user?.name) {
      userArr.push(u);
    }
  });

  useEffect(() => {
    let currCat: number[] = [];
    currCat.push(category);
    let str = currCat[0].toString();
    let newArr = str.split(',');
    if (newArr.includes('139')) {
      setShowUrl(true);
    } else {
      setShowUrl(false);
    }
  }, [category]);

  let rejectedComments = article?.statusAudit.filter((c) => c.status === 'rejected');
  let rejectedComment: string[] | undefined = [];

  rejectedComments?.forEach((c) => {
    if (c.comment !== undefined) {
      let date = new Date(c.createdAt);
      let hour = date.getHours();
      let minute = date.getMinutes();
      return rejectedComment?.push(c.comment + ' - ' + c.authorName + ' (' + hour + ':' + minute + ')');
    }
  });

  let lastComment = rejectedComment[rejectedComment.length - 1];
  let otherComments = rejectedComment.slice(0, -1);
  otherComments.reverse();
  const { Panel } = Collapse;

  return (
    <>
      {dataLoading ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : null}
      {/* <div style={{ height: '20px', background: 'black' }}></div> */}
      <Form
        layout="vertical"
        form={form}
        name="article-form"
        onFinish={onFinish}
        className="article-form"
        onValuesChange={handleFormChange}
      >
        {everythingReady && (
          <>
            <div className="flex" style={{ background: '#F9F9F9' }}>
              <div style={{ width: '100%', padding: '20px', maxWidth: '660px' }} className="flex flex-col">
                <Form.Item
                  label={t('article:External url')}
                  name="url"
                  className={showUrl ? 'article-stamp' : 'hidden'}
                  rules={[{ required: showUrl }]}
                >
                  <Input.TextArea
                    rows={1}
                    style={{ background: '#F9F9F9' }}
                    spellCheck={false}
                    placeholder={t('article:url')}
                  />
                </Form.Item>
                {article?.status === 'rejected' && (
                  <Collapse
                    defaultActiveKey={['0']}
                    style={{ background: '#fff1f0', font: '#cf1322', border: '#ffa39e 1px solid' }}
                  >
                    <Panel header={lastComment} key="1" style={{ listStyle: 'disc outside none' }}>
                      {otherComments.map((oc, i) => (
                        <li key={'issue-' + i} style={{ color: '#cf1322' }}>
                          {oc}
                        </li>
                      ))}
                      {/* <p style={{ color: '#cf1322' }}>{otherComments}</p> */}
                    </Panel>
                  </Collapse>
                )}

                <Form.Item
                  label={t('article:Card label')}
                  name="stamp"
                  className="article-stamp"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={1}
                    style={{ background: '#F9F9F9', width: '90%' }}
                    spellCheck={false}
                    placeholder={t('article:Card label')}
                    maxLength={article?.legacy ? 100 : 25}
                    showCount
                  />
                </Form.Item>

                <Form.Item
                  label={t('article:title')}
                  name="title"
                  className="article-title"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={2}
                    spellCheck={false}
                    placeholder={t('article:title')}
                    maxLength={
                      article?.legacy ? 200 : user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR' ? 1000 : 100
                    }
                    showCount
                    autoSize={true}
                    style={{ width: '90%' }}
                  />
                </Form.Item>
                <Form.Item
                  label={t('article:subtitle')}
                  name="subtitle"
                  className="article-subtitle"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={2}
                    spellCheck={false}
                    placeholder={t('article:subtitle')}
                    maxLength={article?.legacy ? 200 : 150}
                    showCount
                    style={{ width: '90%' }}
                  />
                </Form.Item>

                <BlocksEditor
                  images={articleImages || []}
                  blocks={blocks}
                  articleId={article?.id}
                  highlighting={permissions.approve}
                  onHighLightCalled={handleHighlightCalled}
                  onChange={handleBlocksChange}
                  onImagesChange={handleImagesChange}
                  onThumnailSelect={handleTumbnailSelect}
                  articleType={articleType}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  maxWidth: '350px',
                  alignItems: 'end',
                  justifyContent: 'end',
                  background: 'white',
                  padding: '15px 35px 43px 35px',
                }}
                className="images-section"
              >
                <div>
                  <div>
                    {(user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR') && !article?.status && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<ArrowRightOutlined />}
                        style={article?.status === 'approved' ? { visibility: 'hidden' } : { marginRight: '0px' }}
                        onClick={() => {
                          setForcePublish(true);
                        }}
                        className="pubAndSave"
                      >
                        Objavi i odobri
                      </Button>
                    )}
                    {(user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR') &&
                    user.email !== article?.author.email &&
                    article?.author.email !== undefined &&
                    article?.author.role !== 'EDITOR' &&
                    article?.author.role !== 'ADMINISTRATOR' ? (
                      <>
                        <div className="flex" style={{ justifyContent: 'space-between' }}>
                          {permissions.approve && (
                            <>
                              <Button
                                type="primary"
                                style={
                                  article.status === 'rejected'
                                    ? { marginRight: '18px', visibility: 'hidden' }
                                    : { marginRight: '18px' }
                                }
                                className={`cancel-article-button`}
                                onClick={handleArticleReject}
                              >
                                {t('article:Reject')}
                              </Button>

                              <Button
                                type="primary"
                                style={
                                  article.status === 'approved' ? { visibility: 'hidden' } : { marginRight: '0px' }
                                }
                                onClick={handleArticleApprove}
                                className="publish-article-button"
                              >
                                {t('article:Approve')}
                              </Button>
                            </>
                          )}
                        </div>
                        {permissions.approve && (
                          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Button
                              type="primary"
                              className="publish-article-button w-full save-changes"
                              htmlType="submit"
                              disabled={blocks.length === 0}
                              onClick={showCoverImageWarning}
                            >
                              {id ? t('common:Save changes') : t('common:Publish Article')}
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex" style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
                        <Button htmlType="button" onClick={onReset} className="cancel-article-button">
                          {t('common:Cancel')}
                        </Button>
                        <Button
                          type="primary"
                          className="publish-article-button"
                          htmlType="submit"
                          disabled={blocks.length === 0}
                          onClick={showCoverImageWarning}
                        >
                          {id ? t('common:Save changes') : t('common:Publish Article')}
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* <span>{t('article:Status')}: </span>
                  {article && <b>{t(`article:Status ${article.status}`)} </b>}
                  <Button htmlType="button" onClick={toggleStatusAuditModal}>
                    {t('article:Changes')}
                  </Button> */}
                </div>
                <Button
                  onClick={toggleCoverImageInput}
                  icon={<PlusSquareOutlined />}
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    fontFamily: 'DM Sans',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('images:Choose cover image')}
                </Button>
                {coverImageWarning && <p style={{ color: '#ff4d4f' }}>{t('article:Cover image required')}</p>}
                {coverImagePreview?.imageUrl && (
                  <>
                    <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                      <Picture src={coverImagePreview.imageUrl} style={{ width: '170px', height: '95px' }}></Picture>
                      <Picture
                        src={coverImagePreview.squareThumbnailUrl}
                        style={{ height: '95px', width: '95px' }}
                      ></Picture>
                    </div>
                  </>
                )}
                <Modal
                  title={t('images:Choose cover image')}
                  visible={coverImageInput}
                  onOk={toggleCoverImageInput}
                  onCancel={toggleCoverImageInput}
                  className="imageModal"
                  width={500}
                >
                  <Form.Item
                    name="thumbnailImage"
                    style={{ marginTop: '20px' }}
                    rules={[{ required: true, message: t('article:Cover image required') }]}
                  >
                    <UploadImages maxCount={1} showSquareThumbnail={true} index={-1} isCover={true} />
                  </Form.Item>
                </Modal>

                {articleType === 'special' && (
                  <>
                    <Form.Item
                      name="sponsoredBy"
                      label="Sponsored by"
                      style={{ marginTop: '20px' }}
                      rules={[{ required: false }]}
                    >
                      <UploadImages maxCount={1} showSquareThumbnail={true} index={-1} isCover={true} />
                    </Form.Item>
                    <span className="sponsoredWarning">
                      Pozadina oko sponsored by slike je crna, obratiti pažnju na vidljivost unutar članka.
                    </span>
                  </>
                )}

                {articleType !== 'video' ? (
                  <Form.Item
                    name="textTypeId"
                    style={{ marginTop: '10px' }}
                    label={t('article:Text type')}
                    className="textType"
                    rules={[{ required: true }]}
                  >
                    {textTypes && (
                      <Select
                        showSearch
                        options={
                          user?.role === 'JOURNALIST'
                            ? Object.values(textTypes)
                                .map((type: any) => ({
                                  label: type,
                                  value: type,
                                }))
                                .filter(({ label }) => label !== 'legacy_text')
                            : user?.role !== 'COMMERCIALIST'
                            ? Object.values(textTypesWithNative)
                                .map((type: any) => ({
                                  label: type,
                                  value: type,
                                }))
                                .filter(({ label }) => label !== 'legacy_text')
                            : [
                                {
                                  label: 'Promo tekst',
                                  value: 'Promo tekst',
                                },
                                {
                                  label: 'Native tekst',
                                  value: 'Native tekst',
                                },
                              ]
                        }
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                        onChange={textTypeChange}
                        disabled={articleType === 'mega' || articleType === 'special' || articleType === 'video'}
                      />
                    )}
                  </Form.Item>
                ) : (
                  <p className="sponsoredWarning" style={{ marginTop: '14px' }}>
                    Video
                  </p>
                )}
                {textType === 'Autorski tekst' && (
                  <Form.Item
                    name="subTextTypeId"
                    style={{ marginTop: '10px' }}
                    label={t('article:Sub type')}
                    className="subTextType"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      options={Object.values(subTypes?.data || {}).map((type: any) => ({
                        label: type.subType,
                        value: type.subType,
                      }))}
                      filterOption={(input, option) =>
                        option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                      }
                    />
                  </Form.Item>
                )}
                {textType === 'Agencijski tekst' && (
                  <>
                    <Form.Item
                      name="subTextTypeAg"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="subTextType"
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        options={Object.values(subTypes?.data || {}).map((type: any) => ({
                          label: type.subType,
                          value: type.subType,
                        }))}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="authorId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="author"
                      hidden={true}
                    >
                      <Select
                        showSearch
                        options={usersData?.data.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        }))}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                  </>
                )}
                {textType === 'Preuzeti tekst' && (
                  <>
                    <Form.Item
                      name="subTextTypeId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Sub type')}
                      className="subTextType"
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        options={[
                          {
                            label: 'Servisna/biltenska informacija',
                            value: 'Servisna/biltenska informacija',
                          },
                          {
                            label: 'Prerađeno saopćenje',
                            value: 'Prerađeno saopćenje',
                          },
                          {
                            label: 'Preuzeto sa drugog portala',
                            value: 'Preuzeto sa drugog portala',
                          },
                        ]}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="subTextTypeCustomId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="subTextTypeCustom"
                      rules={[{ required: true }]}
                    >
                      <Input style={{ background: '#F9F9F9' }} spellCheck={false} maxLength={50} showCount />
                    </Form.Item>

                    <Form.Item
                      name="authorId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="author"
                      hidden={true}
                    >
                      <Select
                        showSearch
                        options={usersData?.data.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        }))}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                  </>
                )}

                {textType === 'Prevedeni tekst' && (
                  <>
                    <Form.Item
                      name="subTextTypeCustomId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="subTextTypeCustom"
                      rules={[{ required: true }]}
                    >
                      <Input style={{ background: '#F9F9F9' }} spellCheck={false} maxLength={50} showCount />
                    </Form.Item>
                    <Form.Item
                      name="authorId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="author"
                      hidden={true}
                    >
                      <Select
                        showSearch
                        options={usersData?.data.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        }))}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                  </>
                )}
                {textType === 'Native tekst' && (
                  <Form.Item
                    name="subTextTypeCustomId"
                    style={{ marginTop: '10px' }}
                    label={t('article:Author')}
                    className="subTextTypeCustom"
                    rules={[{ required: true }]}
                  >
                    <Input style={{ background: '#F9F9F9' }} spellCheck={false} maxLength={50} showCount />
                  </Form.Item>
                )}
                {textType === 'Promo tekst' && (
                  <>
                    <Form.Item
                      name="subTextTypeCustomId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="subTextTypeCustom"
                    >
                      <label style={{ color: '#808080' }}>Marketing</label>
                    </Form.Item>
                    <Form.Item
                      name="authorId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="author"
                      hidden={true}
                    >
                      <Select
                        showSearch
                        options={usersData?.data.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        }))}
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                      />
                    </Form.Item>
                  </>
                )}
                {textType !== 'Preuzeti tekst' &&
                  textType !== 'Prevedeni tekst' &&
                  textType !== 'Agencijski tekst' &&
                  textType !== 'Promo tekst' &&
                  textType !== 'Native tekst' && (
                    <Form.Item
                      name="authorId"
                      style={{ marginTop: '10px' }}
                      label={t('article:Author')}
                      className="author"
                    >
                      <Select
                        showSearch
                        options={
                          textType === 'Tekst drugog novinara' && id === 'new'
                            ? userArr?.map((user) => ({
                                label: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                              }))
                            : usersData?.data.map((user) => ({
                                label: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                              }))
                        }
                        filterOption={(input, option) =>
                          option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                        }
                        disabled={textType === 'Autorski tekst' ? true : false}
                      />
                    </Form.Item>
                  )}
                <Form.Item
                  name="categoryId"
                  label={t('article:category')}
                  rules={[{ required: true }]}
                  style={{ marginTop: '10px' }}
                >
                  <Cascader
                    fieldNames={{ label: 'title', value: 'id' }}
                    options={
                      user?.role === 'ALFATV'
                        ? alfaTv
                        : textType !== 'Promo tekst'
                        ? articleType === 'video'
                          ? avazTv
                          : filteredCategories
                        : fiveCategories
                    }
                    style={{ height: '100%' }}
                    disabled={article?.blockPosition !== null && article?.blockPosition !== undefined}
                    changeOnSelect
                    onChange={categoryChange}
                    expandTrigger="hover"
                  />
                </Form.Item>
                <div style={{ marginTop: '10px', color: '#8B8B8B' }}>{t('article:Publication time')}</div>
                <div
                  className="flex"
                  style={{ marginBottom: '10px', marginTop: '10px', justifyContent: 'space-between' }}
                >
                  <button
                    className="publish-now"
                    style={
                      !isActive
                        ? { color: 'white', background: '#333333' }
                        : { background: '#e0e0e0', color: '#333333', fontWeight: 'normal' }
                    }
                    onClick={refuseHandleToggle}
                  >
                    {t('article:Now')}
                  </button>
                  <button
                    className="publish-later"
                    style={
                      isActive
                        ? { color: 'white', background: '#333333' }
                        : { background: '#e0e0e0', color: '#333333', fontWeight: 'normal' }
                    }
                    onClick={handleToggle}
                  >
                    {t('article:Publish later')}
                  </button>
                </div>
                <div className={isActive ? 'block' : 'hidden'}>
                  <Form.Item name="publicationTime">
                    <DatePicker
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        borderRadius: '5px',
                        borderColor: '#d9d9d9',
                        height: '34px',
                      }}
                      showTime
                      format="DD.MM.YYYY HH:mm"
                      className="publication"
                      disabledDate={(current) => {
                        let customDate = moment().format('YYYY-MM-DD');
                        return current && current < moment(customDate, 'YYYY-MM-DD');
                      }}
                    />
                  </Form.Item>
                </div>
                {textType !== 'Promo tekst' && textType !== 'Video' && (
                  <Form.Item
                    name="tags"
                    label={t('article:tags')}
                    style={{ marginTop: '10px' }}
                    rules={[{ required: true }]}
                  >
                    <TagInput />
                  </Form.Item>
                )}
                {relatedArticlesByAuthorWarning && <p style={{ color: '#ff4d4f' }}>{t('article:Author required')}</p>}
                <Form.Item
                  className={relatedArticles.length !== 0 ? 'block' : 'hidden'}
                  name="relatedArticles"
                  label={t('article:Related articles')}
                  style={{ marginTop: '10px' }}
                >
                  <div className="btn-container authorButton">
                    <label className="switch btn-color-mode-switch">
                      <input
                        type="checkbox"
                        name="related_mode"
                        id="related_mode"
                        onChange={(e) => {
                          if (
                            form.getFieldValue('textTypeId') === 'Autorski tekst' ||
                            form.getFieldValue('textTypeId') === 'Tekst drugog novinara'
                          ) {
                            handleAuthorChecked(e.target);
                          }
                        }}
                        checked={tagChecked}
                      />
                      <label
                        htmlFor="related_mode"
                        data-on={t('common:By author')}
                        data-off={t('common:By tag')}
                        className="btn-color-mode-switch-inner"
                      />
                    </label>
                  </div>
                </Form.Item>
                <div
                  style={{ marginTop: '20px', color: '#8B8B8B' }}
                  className={relatedArticles.length !== 0 ? 'block' : 'hidden'}
                >
                  <div className="related-articles-block">
                    {relatedArticles.map((relatedArticle) => (
                      <RelatedArticleCardRA article={relatedArticle} key={relatedArticle.id} />
                    ))}
                  </div>
                </div>
                {articleType === 'standard' && (
                  <>
                    <Form.Item name="commentsDisabled" label={t('article:Comments')} style={{ marginTop: '10px' }}>
                      <div className="btn-container">
                        <label className="switch btn-color-mode-switch">
                          <input
                            type="checkbox"
                            name="color_mode"
                            id="color_mode"
                            //value={comments}
                            checked={!JSON.parse(comments)}
                            onClick={toggleValue}
                            disabled={commentsDisabled}
                          />
                          <label
                            htmlFor="color_mode"
                            data-on={t('common:Disabled')}
                            data-off={t('common:Enabled')}
                            className="btn-color-mode-switch-inner"
                          />
                        </label>
                      </div>
                    </Form.Item>
                  </>
                )}
                <Form.Item name="hasAds" valuePropName="checked" style={{ marginTop: '10px' }}>
                  <Checkbox
                    disabled={hasAdsDisabled}
                    defaultChecked={article ? !article.hasAds : false}
                    onClick={() => setHasAdsChecked(!hasAdsChecked)}
                    checked={hasAdsChecked}
                  ></Checkbox>
                  <label style={{ color: '#8b8b8b', paddingLeft: '10px', fontWeight: '400' }}>
                    Ukoliko tekst uključuje eksplicitan sadržaj (npr. oružje, krv, alkohol, droga, klađenje, seksualni
                    sadržaj, itd.) Molimo kliknite ovdje
                  </label>
                </Form.Item>

                {/* <button
                  onClick={() => {
                    setShowAIModal(true);
                  }}
                  className="create-calendarEntry-button"
                  type="button"
                  style={{
                    height: 35,
                    width: 'inherit',
                    marginTop: '10px',
                    marginBottom: 'auto',
                    backgroundColor: 'white',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                  }}
                >
                  {'Upotreba AI'}
                </button> */}

                {article && article.id && (
                  <Button
                    type="primary"
                    onClick={handlePreviewClick}
                    className="publish-article-button"
                    style={{ marginTop: '30px', width: '100%', paddingTop: '5px' }}
                  >
                    {t('article:Preview')}
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </Form>
      {article && (
        <StatusAuditModal
          statusAudit={article?.statusAudit}
          visible={showStatusAudit}
          onCancel={toggleStatusAuditModal}
        />
      )}
      <ArticleRejectionModal
        visible={showArticleRejectionModal}
        onConfirm={handleArticleRejection}
        onCancel={closeArticleRejectionModal}
      />

      {/* <UseAIModal visible={showAIModal} onClose={toggleUseAIModal} onConfirm={toggleUseAIModal} /> */}
    </>
  );
};
